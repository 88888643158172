import { Component, ViewChild } from '@angular/core';
import { createStore } from 'devextreme-aspnet-data-nojquery';
import { Expense } from '../../shared/models/expense';
import { UploadedEvent, UploadErrorEvent, ValueChangedEvent } from 'devextreme/ui/file_uploader';
import { DxFileUploaderComponent } from 'devextreme-angular/ui/file-uploader';
import { ColumnEditCellTemplateData, RowDblClickEvent, SelectionChangedEvent } from 'devextreme/ui/data_grid';
import { ClickEvent } from 'devextreme/ui/button';
import { environment } from '../../../environments/environment';
import { DxDataGridComponent } from 'devextreme-angular';
import { fromEvent, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ToolbarService } from '../../shared/services/toolbar.service';

@Component({
  selector: 'app-expenses',
  templateUrl: './expenses.component.html',
  styleUrls: ['./expenses.component.scss']
})
export class ExpensesComponent {
  remoteDataSource: any;
  expenseReasonDS: any;
  paymentTypeDS: any;

  tenantId: number;
  workerNumber: string;

  backendURL: string = environment.ApiUrl + '/api';
  imageURL: string = environment.ApiUrl + '/';

  @ViewChild('uploadedImage') uploadedImageRef!: HTMLImageElement;
  @ViewChild('fileUploader') fileUploaderRef!: DxFileUploaderComponent;
  @ViewChild(DxDataGridComponent, { static: false }) grid!: DxDataGridComponent;

  selectedRowIndex = -1;
  modificabile = true;
  retryButtonVisible = false;

  private unsubscriber: Subject<void> = new Subject<void>();

  constructor(private toolbar: ToolbarService) {

    let tenantIdStorage = localStorage.getItem("tenantId");
    tenantIdStorage ??= "0";
    this.tenantId = +tenantIdStorage;

    let user = localStorage.getItem("user");
    if (user == null) user = "   ";
    this.workerNumber = user.substring(3);

    this.remoteDataSource = createStore({
      key: 'expenseId',
      loadUrl: this.backendURL + '/Expenses/Get?tenantId=' + this.tenantId,
      insertUrl: this.backendURL + '/Expenses/Post',
      updateUrl: this.backendURL + '/Expenses/Put',
      deleteUrl: this.backendURL + '/Expenses/Delete',
    });

    this.expenseReasonDS = createStore({
      key: 'expenseReasonId',
      loadUrl: this.backendURL + '/ExpenseReasons/Get?tenantId=' + this.tenantId,
    });

    this.paymentTypeDS = createStore({
      key: 'expensePaymentTypeId',
      loadUrl: this.backendURL + '/ExpensePaymentTypes/Get?tenantId=' + this.tenantId,
    });
  }

  onInitNewRow(e: { data: Expense; }) {
    e.data.tenantId = this.tenantId;
    e.data.fattura = false;
    e.data.workerNumber = this.workerNumber;
    e.data.data = new Date();
  }

  onRowDblClick(e: RowDblClickEvent) {
    this.selectedRowIndex = e.rowIndex;
    this.grid.instance.editRow(this.selectedRowIndex);
    this.grid.instance.deselectAll();
  }

  editRow() {
    this.grid.instance.editRow(this.selectedRowIndex);
    this.grid.instance.deselectAll();
  }

  deleteRow() {
    this.grid.instance.deleteRow(this.selectedRowIndex);
    this.grid.instance.deselectAll();
  }

  addRow() {
    this.grid.instance.addRow();
    this.grid.instance.deselectAll();
  }

  selectedChanged(e: SelectionChangedEvent) {
    let controllata = false;
    if (e.selectedRowsData.length > 0 && e.selectedRowsData[0].controllata) controllata = true;

    this.modificabile = !controllata && e.selectedRowsData.length > 0;

    this.toolbar.EditVisibleChanged(this.modificabile);
    this.toolbar.DeleteVisibleChanged(this.modificabile);

    this.selectedRowIndex = e.component.getRowIndexByKey(e.selectedRowKeys[0]);
  }

  onClick(e: ClickEvent): void {
    // The retry UI/API is not implemented. Use the private API as shown at T611719.
    const fileUploaderInstance = this.fileUploaderRef.instance;
    // @ts-ignore
    for (let i = 0; i < fileUploaderInstance._files.length; i++) {
      // @ts-ignore
      delete fileUploaderInstance._files[i].uploadStarted;
    }
    fileUploaderInstance.upload();
  }

  onValueChanged(e: ValueChangedEvent): void {
    const reader: FileReader = new FileReader();
    reader.onload = (args) => {
      if (typeof args.target?.result === 'string') {
        this.uploadedImageRef.src = args.target.result;
      }
    };
    reader.readAsDataURL(e.value![0]); // convert to base64 string
  }

  onUploaded(e: UploadedEvent, cellInfo: ColumnEditCellTemplateData<Expense, number>): void {
    cellInfo.setValue('images/expenses/' + e.request.responseText);
    this.retryButtonVisible = false;
  }

  onUploadError(e: UploadErrorEvent): void {
    const xhttp = e.request;
    if (xhttp.status === 400) {
      e.message = e.error.responseText;
    }
    if (xhttp.readyState === 4 && xhttp.status === 0) {
      e.message = 'Connection refused';
    }
    this.retryButtonVisible = true;
  }

  customizeTotaleImporto(cellInfo: { value: string; }) {
    if (cellInfo.value == "0") return "";
    return Number(cellInfo.value).toLocaleString("it-IT", { maximumFractionDigits: 2, minimumFractionDigits: 2 });
  }

  ngOnInit(): void {
    this.toolbar.TitleChanged("Note Spese");

    this.toolbar.deleteClickedEvent.pipe(takeUntil(this.unsubscriber)).subscribe(() => this.deleteRow());
    this.toolbar.editClickedEvent.pipe(takeUntil(this.unsubscriber)).subscribe(() => this.editRow());
    this.toolbar.plusClickedEvent.pipe(takeUntil(this.unsubscriber)).subscribe(() => this.addRow());

    this.toolbar.BackVisibleChanged(false);
    this.toolbar.SaveVisibleChanged(false);
    this.toolbar.ForwardVisibleChanged(false);
    this.toolbar.CancelVisibleChanged(false);
    this.toolbar.PlusVisibleChanged(true);
    this.toolbar.EditVisibleChanged(false);
    this.toolbar.DeleteVisibleChanged(false);
    this.toolbar.CopyVisibleChanged(false);
    this.toolbar.MenuVisibleChanged(false);

    history.pushState(null, '');

    fromEvent(window, 'popstate').pipe(
      takeUntil(this.unsubscriber)
    ).subscribe(() => {
      history.pushState(null, '');

    });
  }

  ngOnDestroy(): void {
    this.unsubscriber.next();
    this.unsubscriber.complete();
  }
}
