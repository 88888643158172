import { AfterViewInit, Component, Inject, Input, LOCALE_ID } from '@angular/core';
import { ClockElaboration } from '../../models/clock-elaboration';
import { RestApiService } from '../../services/rest-api.service';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import DataSource from 'devextreme/data/data_source';
import { environment } from '../../../../environments/environment';
import { APIKey, MapMarker } from '../../models/map-marker';
import { Subject, takeUntil } from 'rxjs';
import { formatDate } from '@angular/common';
import { DxDataGridTypes } from 'devextreme-angular/ui/data-grid';

@Component({
  selector: 'app-clock-validation-detail',
  templateUrl: './clock-validation-detail.component.html',
  styleUrls: ['./clock-validation-detail.component.scss'],
  providers: [],
})
export class ClockValidationDetailComponent implements AfterViewInit {
  @Input() key: number | undefined;
  @Input() rowData: ClockElaboration | undefined;

  private unsubscribe: Subject<void> = new Subject<void>();

  backendURL: string = environment.ApiUrl + '/api';

  tenantId: number;
  errorMessage = "";
  errorVisible = false;
  mapVisible = false;
  clocksDataSource: any;
  isPopupVisible = false;

  lookupData = [
        { id: -1, name: 'Timbratura Precedente' },
        { id: 0, name: 'Timbratura Utilizzata' },
        { id: 1, name: 'Tibratura Successiva' },
        { id: 2, name: 'Tibratura Scartata' },
      ];

  markers: MapMarker[] = [];

  apiKey: APIKey = {
    bing: "AuEvZEONNFI4llFDgLvMZK1B_lzJ-fJiPxxuR1ctYG8kMN_pAimVghJ9sGnTDz5L"
  };

  constructor(@Inject(LOCALE_ID) public locale: string, private restApi: RestApiService) {
    this.tenantId = restApi.readTenantId();

  }

  ngAfterViewInit() {
    // carico il
    if (this.rowData && this.rowData.errorMessage) {
      this.errorMessage = this.rowData.errorMessage;
      this.errorVisible = this.rowData.errorMessage != null && (typeof this.rowData.errorMessage === "string" && this.rowData.errorMessage.trim().length !== 0)
    }
    else {
      this.errorMessage = "";
      this.errorVisible = false;
    }

    if (this.key) {

      this.clocksDataSource = new DataSource({
        store: AspNetData.createStore({
          key: 'clockId',
          loadParams: { clockRegistrationId: this.key },
          loadUrl: `${this.backendURL}/ClockElaborations/GetLinks`,
        }),
      });

      this.restApi.getClockMarkers(this.key).pipe(takeUntil(this.unsubscribe)).subscribe((clockMarkers) => {

        this.mapVisible = clockMarkers.length > 0;

        clockMarkers.forEach(clk => {

          if (clk.latitudine && clk.longitudine) {
            const clockDate = clk.deviceDate;
            let direction = clk.direction;
            if (!direction) direction = '';

            const clkMarker = new MapMarker(clk.latitudine, clk.longitudine, 'Timbratura (' + direction + ') ' + formatDate(clockDate, 'HH:mm', this.locale));
            this.markers.push(clkMarker);
          }

          if (clk.latitudineCantiere && clk.longitudineCantiere && clk.cantiere) {
            const clkCantiereMarker = new MapMarker(clk.latitudineCantiere, clk.longitudineCantiere, clk.cantiere);
            clkCantiereMarker.iconSrc = '/assets/construction-marker.png';
            this.markers.push(clkCantiereMarker);
          }

        });
      });
    }
  }

  togglePopup(): void {
    this.isPopupVisible = !this.isPopupVisible;
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  apriMaps(e: DxDataGridTypes.ColumnButtonClickEvent) {
    if (e.row?.data) {
      const url = 'https://www.google.com/maps/@?api=1&map_action=pano&viewpoint=' + e.row?.data.latitudine + '%2C' + e.row?.data.longitudine;
      window.open(url, "_blank");
    }
  }
}
