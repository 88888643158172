<dx-data-grid #grid
  [allowColumnReordering]="true"
  [columnAutoWidth]="true"
  [dataSource]="remoteDataSource"
  [repaintChangesOnly]="true"
  (onInitNewRow)="onInitNewRow($event)"
  (onRowDblClick)="onRowDblClick($event)"
  (onSelectionChanged)="selectedChanged($event)"
  keyField="expenseId">
  <dxo-selection mode="single"></dxo-selection>
  <dxo-grouping [autoExpandAll]="true"></dxo-grouping>
  <dxo-editing mode="popup">
    <dxo-popup title="Nota Spese"
      [showTitle]="true">
    </dxo-popup>
    <dxo-form>
      <dxi-item dataField="data"></dxi-item>
      <dxi-item dataField="importo"></dxi-item>
      <dxi-item dataField="expenseReasonId"></dxi-item>
      <dxi-item dataField="paymentTypeId"></dxi-item>
      <dxi-item dataField="note"
        editorType="dxTextArea"
        [colSpan]="2"
        [editorOptions]="{ height: 100 }">
      </dxi-item>
      <dxi-item dataField="fattura"></dxi-item>
      <dxi-item dataField="workerNumber"
        [visible]="false">
      </dxi-item>
      <dxi-item dataField="foto"></dxi-item>
    </dxo-form>
  </dxo-editing>
  <dxo-filter-row [visible]="false"></dxo-filter-row>
  <dxo-search-panel [visible]="false"></dxo-search-panel>
  <dxi-column dataField="mese"
    dataType="date"
    sortOrder="desc"
    [format]="{ type: 'MMMM yyyy' }"
    [groupIndex]="0">
  </dxi-column>
  <dxi-column dataField="data"
    dataType="date"
    sortOrder="desc"
    [format]="{ type: 'dd/MM' }">

  </dxi-column>
  <dxi-column dataField="expenseReasonId"
    caption="Causale">
    <dxo-lookup [dataSource]="expenseReasonDS"
      valueExpr="expenseReasonId"
      displayExpr="descrizione">
    </dxo-lookup>
  </dxi-column>
  <dxi-column dataField="importo">
    <dxo-format type="fixedPoint" [precision]="2"></dxo-format>
  </dxi-column>

  <dxi-column dataField="paymentTypeId"
    caption="Tipo Pagamento"
    [hidingPriority]="0">
    <dxo-lookup [dataSource]="paymentTypeDS"
      valueExpr="expensePaymentTypeId"
      displayExpr="descrizione">
    </dxo-lookup>
  </dxi-column>
  <dxi-column dataField="note"
    dataType="string"
    cellTemplate="noteTemplate"
    caption="Note">
  </dxi-column>
  <dxi-column dataField="fattura"
    dataType="boolean"
    caption="Fattura">
  </dxi-column>
  <dxi-column dataField="userName"
    dataType="string"
    [visible]="false"
    [allowEditing]="false">
  </dxi-column>
  <dxi-column dataField="foto"
    dataType="string"
    [visible]="false"
    [allowFiltering]="false"
    [allowSorting]="false"
    cellTemplate="cellTemplate"
    editCellTemplate="editCellTemplate">
  </dxi-column>
  <dxo-summary>
    <dxi-group-item column="importo"
      summaryType="sum"
      [customizeText]="customizeTotaleImporto"
      [showInGroupFooter]="false"
      [alignByColumn]="true">
    </dxi-group-item>
  </dxo-summary>
  <div *dxTemplate="let data of 'cellTemplate'">
    <img [src]="imageURL + data.value" />
  </div>
  <div *dxTemplate="let data of 'editCellTemplate'">
    @if (data.value) {
      <img #uploadedImage class="uploadedImage" [src]="imageURL + data.value" />
    }
    <dx-file-uploader #fileUploader
      [multiple]="false"
      accept="image/*"
      uploadMode="instantly"
      [uploadUrl]="backendURL + '/FileUpload/PostExpenses'"
      (onValueChanged)="onValueChanged($event)"
      (onUploaded)="onUploaded($event, data)"
      (onUploadError)="onUploadError($event)">
    </dx-file-uploader>
    <dx-button class="retryButton" text="Retry" [visible]="retryButtonVisible" (onClick)="onClick($event)"></dx-button>
  </div>
  <div *dxTemplate="let data of 'noteTemplate'">
    <div class="multiline-note">{{ data.value }}</div>
  </div>
</dx-data-grid>
<dx-speed-dial-action icon="add"
  label="Aggiungi"
  [index]="1"
  (onClick)="addRow()">
</dx-speed-dial-action>
<dx-speed-dial-action icon="trash"
  label="Cancella"
  [index]="2"
  [visible]="selectedRowIndex !== -1"
  (onClick)="deleteRow()">
</dx-speed-dial-action>
<dx-speed-dial-action icon="edit"
  label="Modifica"
  [index]="3"
  [visible]="selectedRowIndex !== -1"
  (onClick)="editRow()">
</dx-speed-dial-action>
