<div id="registrations">
  <dx-data-grid id="gridContainer"
    [dataSource]="registrations"
    keyExpr="id"
    [showBorders]="false"
    [showColumnHeaders]="false"
    [hoverStateEnabled]="true"
    (onSelectionChanged)="onSelectionChanged($event)"
    (onCellPrepared)="onCellPrepared($event)"
    (onRowPrepared)="onRowPrepared($event)"
    dataRowTemplate="dataRowTemplateName"
    [selectedRowKeys]="[]">
    <dxi-column dataField="dataRegistrazione"
      [groupIndex]="0"
      groupCellTemplate="dataGroupCellTemplate"
      sortOrder="desc"
      caption="Data">

    </dxi-column>
    <dxi-column dataField="descrizioneCantiere"
      [groupIndex]="1"
      groupCellTemplate="groupCellTemplate">
    </dxi-column>
    <dxi-column dataField="fase"></dxi-column>
    <dxi-column dataField="totaleOreManodopera" [width]="70"></dxi-column>
    <dxi-column dataField="totaleOreMezzi" [width]="70" [visible]="mezziVisible"></dxi-column>
    <dxo-selection mode="single"></dxo-selection>
    <dxo-paging [enabled]="false"></dxo-paging>
    <dxo-search-panel [visible]="false"></dxo-search-panel>
    <dxo-group-panel [visible]="false"></dxo-group-panel>
    <dxo-grouping [autoExpandAll]="true"></dxo-grouping>
    <dxo-summary>
      <dxi-group-item column="totaleOreManodopera"
        summaryType="sum"
        [alignByColumn]="true"
        [showInGroupFooter]="false"
        [customizeText]="customizeTotaleOre">
      </dxi-group-item>
      <dxi-group-item column="totaleOreMezzi"
        summaryType="sum"
        [alignByColumn]="true"
        [showInGroupFooter]="false"
        [customizeText]="customizeTotaleOre">
      </dxi-group-item>

    </dxo-summary>

    <div *dxTemplate="let data of 'dataGroupCellTemplate'">
      <div>{{data.value|date:'d/MM/y '}}</div>
    </div>

    <div *dxTemplate="let data of 'groupCellTemplate'">
      <div>{{data.value}}</div>
    </div>

    <ng-container *dxTemplate="let registration of 'dataRowTemplateName'">
      @if (registration.data.fase) {
      <tr><td>&nbsp;</td><td>&nbsp;</td><td [attr.colspan]="registrationColSpan">Fase : <b>{{registration.data.descrizioneFase}}</b></td></tr>
      }
      @if (registration.data.costo) {
      <tr><td>&nbsp;</td><td>&nbsp;</td><td [attr.colspan]="registrationColSpan">Costo : <b>{{registration.data.descrizioneCosto}}</b></td></tr>
      }
      @if (registration.data.notes) {
      <tr><td>&nbsp;</td><td>&nbsp;</td><td [attr.colspan]="registrationColSpan">{{registration.data.notes}}</td></tr>
      }
      @if (registration.data.workerRegistrations.length) {
      <tr><td>&nbsp;</td><td>&nbsp;</td><td [attr.colspan]="registrationColSpan"><b>Dipendenti</b></td></tr>
      }
      @for (item of registration.data.workerRegistrations; track item) {
      <tr>
        <td>&nbsp;</td>
        <td>&nbsp;</td>
        <td>{{item.workerDescription}}</td>
        <td style="text-align:right">{{item.oreLavorate}}</td>
        @if (mezziVisible) {
        <td>&nbsp;</td>
        }
      </tr>
      }
      @if (registration.data.toolRegistrations.length) {
      <tr><td>&nbsp;</td><td>&nbsp;</td><td [attr.colspan]="registrationColSpan"><b>Mezzi</b></td></tr>
      }
      @for (item of registration.data.toolRegistrations; track item) {
      <tr>
        <td>&nbsp;</td>
        <td>&nbsp;</td>
        <td>{{item.toolDescription}}</td>
        <td>&nbsp;</td>
        <td style="text-align:right">{{item.oreLavorate}}</td>
      </tr>
      }
      @if (registration.data.attachments.length && registration.data.attachments.length == 1) {
      <tr><td>&nbsp;</td><td>&nbsp;</td><td [attr.colspan]="registrationColSpan"><b>1 Allegato</b></td></tr>
      }
      @if (registration.data.attachments.length && registration.data.attachments.length > 1) {
      <tr><td>&nbsp;</td><td>&nbsp;</td><td [attr.colspan]="registrationColSpan"><b>{{registration.data.attachments.length}} Allegati</b></td></tr>
      }
      <tr><td class="lastRowRegistration" [attr.colspan]="finalrowColSpan"></td></tr>
    </ng-container>
  </dx-data-grid>
</div>
