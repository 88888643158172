import { Injectable } from '@angular/core';

@Injectable()
export class AppInfoService {
  
  public get title() {
    return 'Hours';
  }

  public get company() {
    return 'Project srl';
  }

  public get version() {
    return '1.7.3';
  }

  public get currentYear() {
    return new Date().getFullYear();
  }
}
