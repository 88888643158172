<div class="content-block dx-card responsive-paddings">
  <dx-form id="form"
           labelLocation="left"
           labelMode="floating"
           [formData]="clock"
           (onFieldDataChanged)="onFieldDataChanged($event)"
           [showValidationSummary]="true"
           validationGroup="registrationData">
    <dxo-editing [allowDeleting]="true">
    </dxo-editing>
    <dxo-col-count-by-screen [xs]="2"></dxo-col-count-by-screen>
    <dxi-item dataField="utcDeviceDate"
              editorType="dxDateBox"
              [isRequired]="true"
              [editorOptions]="dataRegistrazioneEditorOptions"
              [colSpan]="2">
      <dxo-label text="Data e ora"> </dxo-label>
      <dxi-validation-rule type="required"
                           message="La data è richiesta">
      </dxi-validation-rule>
    </dxi-item>
    <dxi-item dataField="direction"
              editorType="dxLookup"
              [editorOptions]="directionEditorOptions"
              [isRequired]="true"
              [colSpan]="2">
      <dxo-label text="Direzione"></dxo-label>
    </dxi-item>
    <dxi-item dataField="workerNumber"
              editorType="dxLookup"
              [editorOptions]="workerEditorOptions"
              [isRequired]="true"
              [colSpan]="2">
      <dxo-label text="Dipendente"></dxo-label>
    </dxi-item>
    <dxi-item dataField="constructionSiteCode"
              editorType="dxLookup"
              [editorOptions]="cantiereEditorOptions"
              [isRequired]="true"
              [colSpan]="2">
      <dxo-label text="Cantiere"></dxo-label>
    </dxi-item>
    <dxi-item dataField="workingPhaseCode"
              editorType="dxLookup"
              [editorOptions]="faseEditorOptions"
              [isRequired]="(option?.workingPhase ?? 0 ) == 2"
              [colSpan]="2"
              [visible]="(option?.workingPhase ?? 0 ) >= 1">
      <dxo-label text="Fase"></dxo-label>
    </dxi-item>
    <dxi-item dataField="notes"
              editorType="dxTextArea"
              [caption]
              [editorOptions]="notesEditorOptions"
              [visible]="option?.notesEnabled"
              [colSpan]="2">
      <dxo-label text="Note"></dxo-label>
    </dxi-item>
  </dx-form>
</div>


  @if (!clock.manual) {
<div class="content-block dx-card responsive-paddings">
  <div>
    <dx-map #map
            center="40.7061, -73.9969"
            [zoom]="18"
            [height]="500"
            width="100%"
            provider="bing"
            [apiKey]="apiKey"
            [controls]="true"
            type="satellite"
            [markers]="markers">
    </dx-map>
  </div>
</div>
  }


